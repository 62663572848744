import * as React from 'react'
import { graphql } from 'gatsby'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import Seo from 'gatsby-plugin-wpgraphql-seo'
import '@wordpress/block-library/build-style/style.css'

import { Layout } from '../components/Layout'
import { SEO } from '../types'

import * as styles from './Page.module.scss'

type Props = {
  data: {
    wpPage: {
      link: string
      id: string
      title: string
      content: string
      status: string
      featuredImage: IGatsbyImageData | null
    }
    seo: SEO
  },
  pageContext: {
    id: string
    theme: string
    uri: string
  }
}

const PageTemplate = ({ data, pageContext }: Props) => (
  <Layout mainClasses={pageContext.theme === 'white' ? "bg-white text-black" : ""}>
    <Seo post={data.wpPage} />
    <div
      className={`${styles.Template} w-full md:w-9/12 xl:w-1/2 px-4 mx-auto my-12 space-y-10`}
    >
      <h1>{data.wpPage.title}</h1>
      <span dangerouslySetInnerHTML={{ __html: data.wpPage.content }} />
    </div>
  </Layout>
)

export default PageTemplate

export const pageQuery = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      link
      id
      title
      content
      status
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
    }
  }
`
